/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it
import React from 'react'

import QueryParamInjector from './src/components/QueryParamInjector'
import { AppProvider } from './src/context/AppContext'

export const wrapRootElement = ({ element }) => (
    <AppProvider>{element}</AppProvider>
)

/**
 * Renders on each page load.
 *
 * It is recommended to do the same in `gatsby-ssr.js`
 */
export const wrapPageElement = ({ element }) => (
    <QueryParamInjector name="uwa-query-params" rootDomain="uwalumni.com">
        {element}
    </QueryParamInjector>
)

export const onPreRouteUpdate = () => {
    document.body.scrollTop = 0
}
