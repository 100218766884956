import React, { useCallback, useEffect } from 'react'

// White-listed query parameters to save.
const allowedQueryParams = ['campaign']

/**
 * Injects saved query parameters into links that match the domain.
 *
 * This is a pass-through component adding behavior to the component tree.
 *
 * @param {string} name The name of the session storage item.
 * @param {string} rootDomain The root domain to match against.
 * @param {React.ReactNode} children The children to render.
 */
export default function QueryParamInjector({ name, rootDomain, children }) {
    /**
     * Saves the query parameters to session storage (if they exist).
     */
    useEffect(() => {
        const saveQueryParamsToSessionStorage = () => {
            const params = new URLSearchParams(document.location.search)

            // toString returns an empty string if there are no params.
            const hasParams = params.toString().length > 0

            if (hasParams) {
                const queryParams = {}
                params.forEach((value, key) => {
                    if (allowedQueryParams.includes(key)) {
                        queryParams[key] = value
                    }
                })

                try {
                    if (typeof sessionStorage !== 'undefined') {
                        sessionStorage.setItem(
                            name,
                            JSON.stringify(queryParams),
                        )
                    } else {
                        console.error('Session storage is not available.')
                    }
                } catch (error) {
                    console.error(
                        'Error saving query params to session storage',
                        error,
                    )
                }
            }
        }

        saveQueryParamsToSessionStorage()
    }, [name])

    /**
     * Injects saved query parameters into links that
     * match the root domain.
     */
    const injectQueryParamsIntoLinks = useCallback(
        event => {
            const link = event.target.closest('a')

            if (link) {
                const href = link.getAttribute('href')

                try {
                    const url = new URL(href, document.baseURI)

                    if (url.hostname.endsWith(`.${rootDomain}`)) {
                        const savedParams = sessionStorage.getItem(name)
                        if (savedParams) {
                            const parsedParams = JSON.parse(savedParams)
                            event.preventDefault()
                            Object.keys(parsedParams).forEach(key => {
                                url.searchParams.set(key, parsedParams[key])
                            })
                            if (event.ctrlKey || event.metaKey) {
                                window.open(url.toString(), '_blank')
                            } else {
                                document.location.href = url.toString()
                            }
                        }
                    }
                } catch (error) {
                    console.error(
                        'Error injecting query params into links',
                        error,
                    )
                }
            }
        },
        [rootDomain, name],
    )

    /**
     * Register the event listener to listen for clicks.
     */
    useEffect(() => {
        const handleKeyDown = event => {
            if (event.key === 'Enter') {
                injectQueryParamsIntoLinks(event)
            }
        }
        document.addEventListener('click', injectQueryParamsIntoLinks)
        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('click', injectQueryParamsIntoLinks)
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [injectQueryParamsIntoLinks])

    return <>{children}</>
}
